import axios, { AxiosRequestConfig } from "axios";
import * as SecureStore from "expo-secure-store"
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
const axiosClient = axios.create({
    baseURL: "https://api.elphinstone.us/v1/accounts"
    // baseURL: process.env.NODE_ENV === "production" ? "https:///api-business.elphinstone.com.pk/b2c" : "https://staging-api-business.elphinstone.com.pk/b2c"
})




export default axiosClient
