import React from 'react';
import { TouchableOpacity, Image, ToastAndroid, Alert, Platform } from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';

const CopyToClipboardWallet = ({ walletAddress }) => {
  const copyToClipboardWallet = () => {
    Clipboard.setString(
      `Your wallet address is ${walletAddress}`
    );

    if (Platform.OS === 'android') {
      ToastAndroid.show('Text copied to clipboard', ToastAndroid.SHORT);
    } else {
      Alert.alert('Copied to clipboard', 'Text copied to clipboard');
    }
  };

  return (
    <TouchableOpacity onPress={copyToClipboardWallet}>
      <Image source={require('../../assets/images/clipboard.png')} style={{ width: 24, height: 24, marginRight:'60%',marginBottom:8}} />
    </TouchableOpacity>
  );
};

export default CopyToClipboardWallet;
