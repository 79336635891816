import React, { useState, useRef, useEffect } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  Dimensions,
  TouchableOpacity,
  Modal,
  Button,
  Image,
  TextInput,
  TouchableWithoutFeedback,
} from "react-native";
import {
  Octicons,
  Feather,
  MaterialIcons,
  MaterialCommunityIcons,
  AntDesign,
  Ionicons,
  Entypo,
} from "@expo/vector-icons";
import Constants from "expo-constants";
import { nasdaq100, snp500, dji30 } from "../../assets/sampleIndices";
import MarketItem from "./AppDashboardHome/MarketItem";
import InvestItem from "./AppDashboardMyPortfolio/InvestmentItem";
import AlpacaService from "../../api/lib/AlpacaService";
import { UserPostion } from "../Interfaces/UserPositionInterface";
import { StockDictionary } from "../Interfaces/StockDictionaryInterface";
import ResponseModal from "../components/ResponseModal";
import { TransactionItem } from "./AppDashboardTransactions/TransactionItem";
import {
  TransferInItem,
  TransferOutItem,
} from "./AppDashboardTransactions/TransferItem";
import { AppConstants } from "../../assets/AppConstants";
import { DividendsItem } from "./AppDashboardTransactions/DividendsItem";

function convertDateFormat(datetime: any) {
  const date = new Date(datetime);
  const options = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const dateString = date.toLocaleDateString("en-US", options as any);
  const timeString = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return `${dateString}`;
}

export default function AppDashboardMyTransactions({
  navigation,
  account_id,
  userPositions,
  stockDictionary,
  userBalances,
  alpacaService,
  alpacaBrokerAPIService,
  refreshFunction,
  miscValues,
}: {
  navigation: any;
  account_id?: string;
  userPositions: UserPostion[];
  stockDictionary: StockDictionary[];
  userBalances: any;
  refreshFunction: any;
  alpacaService: AlpacaService;
  alpacaBrokerAPIService: any;
  miscValues: any;
}) {
  const [showResponseModal, setResponseModal] = useState(false);
  const [responseModalConfig, setResponseModalConfig] = useState({
    isSuccess: null,
    message: "",
    subMessage: "",
  });
  function launchResponseModal({ message, subMessage, isSuccess }: any) {
    setResponseModalConfig({
      message,
      subMessage,
      isSuccess,
    });
    setResponseModal(true);
  }

  const [selectedCategory, setCategory] = useState<any>("Transactions");
  const [transferCategory, setTransferCategory] = useState<any>("in");
  const [transactionsObject, setTransactionsObject] = useState<any>([]);
  const [dividendsObject, setDividendsObject] = useState<any>([]);
  const [transefersInList, setTransfersInList] = useState<any>([]);
  const [transefersOutList, setTransfersOutList] = useState<any>([]);
  const [corporateActions, setCorporateActions] = useState<any>([]);
  useEffect(() => {
    alpacaService
      .getTransactionHistory()
      .then((cb) => setTransactionsObject(cb));
  }, []);

  useEffect(() => {
    alpacaService
      .getAccountActivites()
      .then((cb) => setDividendsObject(cb))
      .catch((err) => console.log(`ERROR WHILE GETTING ACTIVITIES: `, err))
    console.log(`ACTIVIITES: `, dividendsObject);

    console.log(dividendsObject);
  }, []);

  useEffect(() => {
    alpacaService
      .getCorporateActions()
      .then((cb) => setCorporateActions(cb)) 
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    
  })

  function getStockFromSymbol(symbol: string) {
    if (stockDictionary) {
      return stockDictionary.filter((cb: any) => cb.symbol === symbol)[0]?.name;
    }
    return "";
  }

  useEffect(() => {
    alpacaService.getJournalsIn().then((cb) => setTransfersInList(cb));
    alpacaService.getJournalsOuts().then((cb) => setTransfersOutList(cb));
  }, []);

  return (
    <ScrollView showsVerticalScrollIndicator={false} bounces={false}>
      <ResponseModal
        show={showResponseModal}
        closeModal={() => setResponseModal(false)}
        {...responseModalConfig}
        onClose={refreshFunction}
      />

      <View
        style={{
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor:  "rgba(0, 23, 139,0.8)",
          paddingTop: 150,
        }}
      >
        <View style={{ borderRadius: 6, height: 301, width: "100%" }}>
          <View style={{ alignSelf: "flex-start", paddingHorizontal: 30 }}>
            <Text
              style={{
                fontFamily: "Overpass_700Bold",
                fontSize: 40,
                color: "white",
              }}
            >
              $
              {userBalances.portfolio_value &&
                parseFloat(userBalances?.portfolio_value).toLocaleString(
                  undefined,
                  {
                    currency: "USD",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }
                )}
            </Text>
            <Text
              style={{ fontFamily: "ArialNova", fontSize: 16, color: "white" }}
            >
              Total Portfolio Value
            </Text>
          </View>
        </View>

        <View
          style={{
            backgroundColor: "#fff",
            width: Dimensions.get("window").width * 0.8,
            height: 180,
            marginTop: -120,
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.1,
            shadowRadius: 9,
            elevation: 5,
            borderRadius: 6,
          }}
        >
          <View
            style={{
              width: "100%",
              height: "100%",
              borderBottomColor: "#97979744",
              borderBottomWidth: 0,
              padding: 20,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <View> */}
              {/* <View>
                <Text
                  style={{
                    fontFamily: "ArialNova",
                    color: "#1E293BCC",
                    paddingBottom: 10,
                    fontSize: 11,
                  }}
                >
                  Portfolio value in PKR
                </Text>
                <Text
                  style={{
                    fontFamily: "Overpass_700Bold",
                    fontSize: 24,
                  }}
                >
                  {(
                    miscValues.USDPKR *
                    (userBalances.portfolio_value
                      ? parseFloat(userBalances?.portfolio_value)
                      : 0)
                  ).toLocaleString(undefined, {
                    currency: "USD",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  })}
                </Text>
              </View> */}
            {/* </View> */}
          </View>
        </View>

        <ScrollView
          bounces={false}
          showsHorizontalScrollIndicator={false}
          horizontal={true}
        >
          <View
            style={{
              width: "100%",
              paddingHorizontal: 30,
              flexDirection: "row",
              marginTop: 30,
              marginBottom: 10,
            }}
          >
            <TouchableWithoutFeedback
              onPress={() => setCategory("Transactions")}
            >
              <View
                style={{
                  borderBottomColor: AppConstants.loginHeaderBlue,
                  borderBottomWidth:
                    selectedCategory === "Transactions" ? 2 : 0,
                }}
              >
                <Text style={{ fontFamily: "Overpass_700Bold", fontSize: 16 }}>
                  Transactions
                </Text>
              </View>
            </TouchableWithoutFeedback>
            <View style={{ width: 20 }} />

            <TouchableWithoutFeedback
              onPress={() => setCategory("Dividends")}
            >
              <View
                style={{
                  borderBottomColor: AppConstants.loginHeaderBlue,
                  borderBottomWidth:
                    selectedCategory === "Dividends" ? 2 : 0,
                }}
              >
                <Text style={{ fontFamily: "Overpass_700Bold", fontSize: 16 }}>
                Dividends
                </Text>
              </View>
            </TouchableWithoutFeedback>
            <View style={{ width: 20 }} />

            <TouchableWithoutFeedback onPress={() => setCategory("Transfers")}>
              <View
                style={{
                  borderBottomColor: AppConstants.loginHeaderBlue,
                  borderBottomWidth: selectedCategory === "Transfers" ? 2 : 0,
                }}
              >
                <Text style={{ fontFamily: "Overpass_700Bold", fontSize: 16 }}>
                  Transfers
                </Text>
              </View>
            </TouchableWithoutFeedback>

            <View style={{ width: 20 }} />

            <TouchableWithoutFeedback
              onPress={() => setCategory("Corporate Transactions")}
            >
              <View
                style={{
                  borderBottomColor: AppConstants.loginHeaderBlue,
                  borderBottomWidth:
                    selectedCategory === "Corporate Transactions" ? 2 : 0,
                }}
              >
                <Text style={{ fontFamily: "Overpass_700Bold", fontSize: 16 }}>
                  Corporate Actions
                </Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </ScrollView>

        {selectedCategory === "Transfers" && (
          <View
            style={{
              width: "100%",
              paddingHorizontal: 30,
              flexDirection: "row",
              marginVertical: 5,
              marginBottom: 30,
            }}
          >
            <TouchableWithoutFeedback onPress={() => setTransferCategory("in")}>
              <View
                style={{
                  borderBottomColor: AppConstants.loginHeaderBlue,
                  borderBottomWidth: transferCategory === "in" ? 1 : 0,
                }}
              >
                <Text style={{ fontFamily: "Overpass_700Bold", fontSize: 12 }}>
                  Transfers In
                </Text>
              </View>
            </TouchableWithoutFeedback>
            <View style={{ width: 20 }} />

            <TouchableWithoutFeedback
              onPress={() => setTransferCategory("out")}
            >
              <View
                style={{
                  borderBottomColor: AppConstants.loginHeaderBlue,
                  borderBottomWidth: transferCategory === "out" ? 1 : 0,
                }}
              >
                <Text style={{ fontFamily: "Overpass_700Bold", fontSize: 12 }}>
                  Transfers Out
                </Text>
              </View>
            </TouchableWithoutFeedback>
          </View>
        )}
        
        {selectedCategory === "Dividends" &&
          dividendsObject.map((cb: any) => {
            if (cb.activity_type == "DIVNRA" || cb.activity_type == "DIV") {

              return (
                <DividendsItem
                  {...cb}
                  name={getStockFromSymbol(cb.symbol)}
                  key={cb.id}
                />
              )
            }
          })}

        {selectedCategory === "Transactions" &&
          transactionsObject.map((cb: any) => (
            <TransactionItem
              {...cb}
              name={getStockFromSymbol(cb.symbol)}
              key={cb.client_order_id}
              cancelOrderFunction={() =>
                alpacaService
                  .cancelPendingOrder(cb.id)
                  .then((cb: any) => {
                    launchResponseModal({
                      message: `Your order cancellation request has been submitted successfully`,
                      subMessage:
                        "We’ve sent you a confirmation email. Please check your inbox.",
                      isSuccess: true,
                    });
                  })
                  .catch((cb: any) => {
                    console.log(cb);
                    launchResponseModal(AppConstants.GenericErrorResponse);
                  })
              }
            />
          ))}

        {selectedCategory === "Transfers" &&
          transferCategory === "in" &&
          transefersInList.map((transferObject: any) => (
            <TransferInItem {...transferObject} key={transferObject.id} />
          ))}

        {selectedCategory === "Transfers" &&
          transferCategory === "out" &&
          transefersOutList.map((transferObject: any) => (
            <TransferOutItem {...transferObject} key={transferObject.id} />
          ))}

        {selectedCategory === "Corporate Transactions" &&
          corporateActions.map((item: any, index: any) => (
            <CorporateActionItem {...item} key={index} />
          ))}

        <View style={{ height: 100 }} />
      </View>

      <View style={{ zIndex: -1, position: "absolute", width: "100%" }}>
        <Image source={require("../../assets/nyse.png")} style={{}} />
        <View
          style={{
            backgroundColor: "rgba(0, 23, 139,0.8)",
            position: "absolute",
            height: "100%",
            width: "100%",
          }}
        ></View>
      </View>
    </ScrollView>
  );
}
const ActionTypeMap = {
  dividend: {
    cash: "Cash Dividend",
    stock: "Stock Dividend",
  },
  merger: {
    merger_completion: "Merger",
  },
  split: {
    reverse_split: "Reverse Split",
    stock_split: "Stock Split",
    unit_split: "Unit Split",
  },
  spinoff: {
    spinoff: "Spinoff",
  },
};

const CorporateActionItem = (props: any) => {
  const {
    ca_type,
    declaration_date,
    record_date,
    payable_date,
    ca_sub_type,
    initiating_symbol,
    target_symbol,
    new_rate,
    old_rate,
    effective_date,
    cash,
  } = props;
  if (ca_sub_type === "merger_update") {
    return null;
  }
  return (
    <View
      style={{
        width: Dimensions.get("window").width * 0.9,
        borderRadius: 6,
        backgroundColor: "#fff",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.1,
        shadowRadius: 9,
        elevation: 5,
        marginVertical: 10,
        // overflow: 'hidden'
      }}
    >
      <View style={{ padding: 20 }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text style={{ fontFamily: "Overpass_700Bold", fontSize: 18 }}>
            {ActionTypeMap[ca_type][ca_sub_type]}
          </Text>
          <View>
            <Text style={{ fontFamily: "ArialNova", fontSize: 12 }}>
              {record_date && convertDateFormat(record_date)}
            </Text>
          </View>
        </View>
        <Text style={{ fontFamily: "Overpass_400Regular" }}>
          {ca_type === "dividend" &&
            ca_sub_type === "cash" &&
            `Cash dividend ${cash} per share`}
          {ca_type === "dividend" &&
            ca_sub_type === "stock" &&
            `Stock dividend ${((new_rate - old_rate) * 100).toLocaleString(
              undefined,
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}% per share`}
          {ca_type === "merger" &&
            ca_sub_type === "merger_completion" &&
            `${initiating_symbol} will acquire ${target_symbol}`}
          {ca_type === "split" &&
            ca_sub_type === "reverse_split" &&
            `Stock reverse split ${old_rate}:${new_rate}`}
          {ca_type === "split" &&
            ca_sub_type === "stock_split" &&
            `Stock split ${old_rate}:${new_rate}`}
          {ca_type === "split" &&
            ca_sub_type === "unit_split" &&
            `${initiating_symbol} is splitting out ${target_symbol}`}
          {ca_type === "spinoff" &&
            ca_sub_type === "spinoff" &&
            `${initiating_symbol} will spin off ${target_symbol}`}
        </Text>
      </View>
      <View
        style={{
          padding: 20,
          justifyContent: "center",
          borderTopWidth: 0.25,
          borderColor: "#bbb",
        }}
      >
        <Text style={{ fontFamily: "ArialNova", lineHeight: 24, fontSize: 13 }}>
          {ca_type === "dividend" &&
            ca_sub_type === "cash" &&
            `Declared on ${convertDateFormat(
              declaration_date
            )} for shareholders of ${target_symbol} who own the shares on ${convertDateFormat(
              record_date
            )}, and will be paid on ${convertDateFormat(payable_date)}`}
          {ca_type === "dividend" &&
            ca_sub_type === "stock" &&
            `Declared on ${convertDateFormat(
              declaration_date
            )} for shareholders ${target_symbol} who own the shares on ${convertDateFormat(
              record_date
            )}, and will be paid on ${convertDateFormat(payable_date)}`}
          {ca_type === "merger" &&
            ca_sub_type === "merger_completion" &&
            `All shareholders of ${target_symbol} will receive ${new_rate} shares of ${initiating_symbol} for every ${old_rate} share of ${target_symbol} that they own. This transaction is expected to close on ${convertDateFormat(
              effective_date
            )}.`}
          {ca_type === "split" &&
            ca_sub_type === "reverse_split" &&
            `${target_symbol} is reducing its number of shares in a ${old_rate}:${new_rate} ratio. This change will go into effect on ${convertDateFormat(
              payable_date
            )} and will reduce your number of shares - and increase the price of the shares - accordingly. This will not directly affect that value of your shares.`}
          {ca_type === "split" &&
            ca_sub_type === "stock_split" &&
            `${target_symbol} is increasing its number of shares in a ${old_rate}:${new_rate} ratio. This change will go into effect on ${convertDateFormat(
              payable_date
            )} and will increase your number of shares - and decrease the price of the shares - accordingly. This will not directly affect that value of your shares.`}
          {ca_type === "split" &&
            ca_sub_type === "unit_split" &&
            `All shareholders of ${initiating_symbol} will receive ${new_rate} units of ${target_symbol} for every ${old_rate} share of ${initiating_symbol}. The new shares will be available on ${convertDateFormat(
              effective_date
            )}.`}
          {ca_type === "spinoff" &&
            ca_sub_type === "spinoff" &&
            `All shareholders of ${initiating_symbol} will receive ${new_rate} shares of ${target_symbol} for every ${old_rate} share of ${initiating_symbol} that they own as of ${convertDateFormat(
              record_date
            )}. The new shares will be available on ${convertDateFormat(
              payable_date
            )}.`}
        </Text>
      </View>
    </View>
  );
};
