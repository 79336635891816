import { Tooltip, TooltipProps } from '@rneui/themed';
import React,{useState} from 'react'
import {
    TouchableOpacity,
    Text,
    ScrollView,
    View,
    Dimensions,
    FlatList,
    Platform
} from 'react-native'
import {Feather} from '@expo/vector-icons'
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import DateTimePickerModal from "react-native-modal-datetime-picker";
import { AppConstants } from '../../../../../assets/AppConstants';
import {DateTimePickerAndroid} from '@react-native-community/datetimepicker';



export default function CustomDatePicker({selectedValue, setValue, maximumDate} : {selectedValue : any, setValue : any, maximumDate? :any}){
    const [open, setOpen] = useState(false)

    const showAndroidDatePicker = () => {
        DateTimePickerAndroid.open({
          value: selectedValue || new Date(),
          onChange: (event, newDate) => setValue(newDate),
          mode: 'date',
          is24Hour: true,
          maximumDate: maximumDate,
        });
      };
    
      const handlePress = () => {
        if (Platform.OS === 'ios') {
          setOpen(!open);
        } else if (Platform.OS === 'android') {
          showAndroidDatePicker();
        } else if (Platform.OS === 'web') {
          // For web, you could use a custom state to trigger the web-based date picker
          setOpen(!open);
        }
      };
    return (
            // <TouchableOpacity
            // style={{
            //     height:64,
            //     width:Dimensions.get('window').width * 0.9,
            //     borderWidth:1,
            //     borderColor:'#ddd',
            //     borderRadius:6,
            //     marginTop:10,
            //     justifyContent:'space-between',
            //     alignItems:'center',
            //     padding:10,
            //     flexDirection:'row'
            // }}
            // onPress={handlePress}
            // activeOpacity={1}
            // >
            //     <View style={{flexDirection:'row',}}>
            //     <Text style={{paddingLeft:10}}>
            //         {selectedValue === '' ? 'e.g., 12/31/2028' : selectedValue.toLocaleDateString()}
            //     </Text>
            //     </View>
            //     <Feather name='calendar' size={20}/>

            //     {Platform.OS === 'ios' && <DateTimePickerModal
            //         isVisible={open}
            //         mode="date"
            //         onConfirm={(cb) => {setValue(cb); setOpen(!open)}}
            //         onCancel={()=>setOpen(!open)}
            //         display="inline"
            //         accentColor={AppConstants.loginHeaderBlue}
            //         maximumDate={maximumDate}
            //     /> 


            
            // }
            <>
            {Platform.OS === 'web'  && (
            <DatePicker
            showIcon
            toggleCalendarOnIconClick
            selected={selectedValue}
            onSelect={(date) => {
                console.log("DATE",date)
                setValue(date);
                setOpen(!open);
            }}
            maxDate={maximumDate}
            />
        )}
        </>
           
    )
}
