import axios, { AxiosRequestConfig } from "axios";
import * as SecureStore from 'expo-secure-store'

const axiosClientBalanceService = axios.create({
    baseURL: "https://api.elphinstone.us/v1/user-balance"
    // baseURL: process.env.NODE_ENV === "production" ? "https:///api-business.elphinstone.com.pk/b2c" : "https://staging-api-business.elphinstone.com.pk/b2c"
})




export default axiosClientBalanceService
