import axios, { AxiosRequestConfig } from "axios";
import * as SecureStore from 'expo-secure-store'
const axiosClientReferralService = axios.create({
    baseURL: "https://api.elphinstone.us/v1/users/" //Change to actual port on deployment
})




export default axiosClientReferralService
